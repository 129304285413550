import { createStore } from 'vuex'
import * as OM from '@/model';
import { StorageServices } from '@/services/StorageServices';

let store = createStore({
    state: {
        showSpinner: 0,
        evento: <OM.Evento>null,
        ticket: <OM.Ticket>null
    },
})

store.state.evento = StorageServices.getEvento();
store.state.ticket = StorageServices.getTicket();

export default store;