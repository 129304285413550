
import * as OM from '@/model'
import { baseUrl } from '@/config';
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';

@Options({})
export default class regolamento extends Vue {

    evento: OM.Evento = StorageServices.getEvento();
    get href(){
        if(this.evento.type == 0)
            return baseUrl + "wwwroot/regolamentoFestival.pdf";
        else
            return baseUrl + "wwwroot/regolamentoLiveMusic.pdf";
    }

}
