import * as OM from '@/model';
import store from '@/store';

class _StorageServices  {
    
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }
    
    getEvento() {
        return this.readFromLocalStorage<OM.Evento>('Evento');
    }
    setEvento(Evento: OM.Evento) {
        window.localStorage.setItem('Evento', JSON.stringify(Evento));
    }
    
    getTicket() {
        return this.readFromLocalStorage<OM.Ticket>('Ticket');
    }
    setTicket(Ticket: OM.Ticket) {
        window.localStorage.setItem('Ticket', JSON.stringify(Ticket));
    }
    
}

export let StorageServices = new _StorageServices();