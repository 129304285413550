
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from './services/StorageServices';
import store from './store';

@Options({})
export default class App extends Vue {

    mounted(){
        let totalHeight = window.innerHeight;
        document.querySelector('html').style.setProperty('--totalheight', totalHeight + "px");

        window.addEventListener('resize', () => {
            let totalHeight = window.innerHeight;
            document.querySelector('html').style.setProperty('--totalheight', totalHeight + "px");
        })
    }

    fullscreen(){
        var elem: any = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }

}
