import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'loginEvento',
        component: () => import('../views/loginEvento.vue')
    },
    {
        path: '/tappo',
        name: 'tappo',
        component: () => import('../views/tappo.vue')
    },
    {
        path: '/iniziaGiocare',
        name: 'iniziaGiocare',
        component: () => import('../views/iniziaGiocare.vue')
    },
    {
        path: '/scratch',
        name: 'scratch',
        component: () => import('../views/scratch.vue')
    },
    {
        path: '/haiVinto',
        name: 'haiVinto',
        component: () => import('../views/haiVinto.vue')
    },
    {
        path: '/haiPerso',
        name: 'haiPerso',
        component: () => import('../views/haiPerso.vue')
    },
    {
        path: '/form',
        name: 'form',
        component: () => import('../views/form.vue')
    },
    {
        path: '/grazie',
        name: 'grazie',
        component: () => import('../views/grazie.vue')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
