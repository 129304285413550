import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinnerAll"
}
const _hoisted_2 = { class: "mainContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mini_spinner = _resolveComponent("mini-spinner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_modals_container = _resolveComponent("modals-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$store.state.showSpinner > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_mini_spinner)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "fullscreenArea",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.fullscreen && _ctx.fullscreen(...args)))
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_modals_container)
  ], 64))
}