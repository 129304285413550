import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import FILTERS from '@/filters';

import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/css/style.css'

// import '@/dragdroptouch';

import { CommonServices } from '@/services/CommonServices';


import moment from 'moment';
import modalPlugin from './plugins/modalPlugin';
moment.locale("IT-it");

const app = createApp(App)
.use(store)
.use(router)
.use(modalPlugin);

app.config.globalProperties.$filters = FILTERS;

const ComponentContext = require.context('./components', true, /\.vue$/i);
ComponentContext.keys().forEach(fileName => {
    var component = ComponentContext(fileName);
    let componentName = fileName.split('/').pop().split('.')[0];
    app.component(componentName, (<any>component).default || component);
})

app.mount('#app');